@import 'globals'

.card-with-button
  display: inline-block
  position: relative
  height: 276px
  box-sizing: border-box
  margin-top: 66px
  border-width: 2px
  padding-bottom: 40px
  font-family: 'Open Sans', sans-serif
  h2
    margin: 25px 0 0 0
    text-align: center
    color: TEXT_COLOR
    font-family: 'Lato', sans-serif
    font-size 22px
    font-weight normal
    letter-spacing: 0.53px
  button, a
    display: block
    position: absolute
    width: 100%
    height: 40px
    bottom: 0
    padding: 0
    border-radius: 0
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px
    font-size: 18px
    letter-spacing: 0.43px
    cursor: pointer
  a, a:hover, a:visited, a:active
    line-height: 40px
    color: #ffffff
    text-align: center
    text-decoration: none
