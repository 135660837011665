@import 'globals'

.login-form-container
.search-form-container
  width: 686px
  color: TEXT_COLOR
  &:before, &:after
    content: ' '
    display: block
    position: absolute
    top: 0
  &:before
    width: 97px
    height: 103px
    left: 0
    background-image: url('../assets/images/triangles_top_left.svg')
  &:after
    width: 103px
    height: 97px
    right: 0
    background-image: url('../assets/images/triangles_top_right.svg')
  fieldset
    border: none
    padding: 0 64px
    label
      display: block
      margin: 18px 0 4px
      padding-left: 15px
      font-size: 14px
      color: TEXT_ALT_COLOR
      text-align left
    input
      display: block
      width: 100%
      height: 40px
      padding: 0 15px
      box-sizing: border-box
