@import 'globals'

.outcomes-container
  width: 1106px
  .btn-back
    margin-bottom: 10px
  a, a:visited, a:hover, a:active
    text-decoration: none
    color: inherit
  .ms-container
    margin-bottom: 70px
    padding: 32px 46px 42px
    .title-text
    .upper-container
      margin-bottom: 32px
    .upper-container
      display: flex
      justify-content: space-between
      align-items: center
      input
        height: 18px
        width: 558px
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2)
    .outcomes-list
      .outcomes-list-item
        position: relative
        height: 40px
        line-height: 36px
        box-sizing: border-box
        border-top: 2px solid #ffffff
        border-bottom: 2px solid #ffffff
        font-family: 'Rajdhani', sans-serif
        font-weight: bold
        letter-spacing: 0.41px
        color: LABEL_PRIMARY_COLOR
        background-color: HEADER_BACKGROUND
        &:hover
          background MARKER_STATE_BACKGROUND
        &.expanded
          height: initial
          &:hover
            background: HEADER_BACKGROUND
        .outcome-name
          padding: 0 15px
          font-size: 18px
          letter-spacing: 0.43px
          &.editing
          &.editing:hover
            margin-bottom: 10px
            background-color: #ffffff
            color: #000000
          .edit-container
            > input
              height: 18px
              width: 60%
              border: none
              padding: 4px
              font-size: 17px
              letter-spacing: 0.41px
              color: #000000
        .controls
          position: absolute
          right: 15px
          top: 0
          button
            width: 18px
            height: 18px
            &.delete
              margin-right: 10px
        .expand
        .collapse
          position: absolute
          left: 50%
          bottom: 0
        .spinner-icon
          height: 40px
          width: @height
        .marker-states-list
          padding: 0 15px
          margin-bottom: 30px
          .marker-states-list-item
            font-size: 15px
            font-weight: normal
            color: #000000
            letter-spacing: 0.36px
            border-bottom: 2px solid #ffffff
            &:first-child
              border-top: 2px solid #ffffff

  .modal-dialog .confirmation-dialog.delete-outcome
    width: 722px
    left: calc((100vw - 772px) / 2)
    height: 205px
    padding: 43px 34px 30px
    .title
      font-size: 18px
    .outcome-name
      color: LABEL_PRIMARY_COLOR
