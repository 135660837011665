@import 'globals'
.tabs-container

  .tabspane
    display: flex
    flex-direction: row
    justify-content: flex-end
    height: 35px
    box-shadow: inset 0px -2px 3px 0px rgba(0, 0, 0, 0.2)
    border-bottom: 6px solid TAB_SELECTED_BACKGROUND

    .tab
      flex: 0 0 174px
      align-self: flex-end
      line-height: 34px
      margin: 0 1px
      text-align: center
      background-color: TAB_DEFAULT_BACKGROUND
      color: #fff
      cursor: pointer
      user-select: none
      border-top-left-radius: 2px
      border-top-right-radius: 2px
      box-shadow: inset 0px -2px 3px 0px rgba(0, 0, 0, 0.2)
      &:first-child
        margin: 0 1px 0 0
      &:last-child
        margin: 0 0 0 1px
      &:hover
        background-color: lighten(TAB_DEFAULT_BACKGROUND, 2)

    .tab--selected
      box-shadow: 0px -2px 11px 0px rgba(0,0,0,0.2)
      background-color: TAB_SELECTED_BACKGROUND
      &:hover
        background-color: TAB_SELECTED_BACKGROUND

    .tab--attention
      background-color: TAB_ATTENTION_BACKGROUND !important

    .tab--disabled
      background-color: TAB_DISABLED_BACKGROUND !important
      cursor: default
      &:hover
        background-color: TAB_DISABLED_BACKGROUND !important
  .tab-content
    position: relative
