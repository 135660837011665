@import 'globals'

header
  height: 60px
  position: relative
  background-color: HEADER_BACKGROUND
  font-family: 'Lato', sans-serif
  letter-spacing: 0.38px
  z-index: 3
  box-shadow 0 2px 15px -5px black
  &.wider
    height: 87px
  .header-container
    display: inline-block
    position: relative
  img
    height: 40px
    vertical-align: middle
    display: inline-block
    box-sizing: border-box
    &.ms-logo
      margin-left: 20px
    &.grade-logo
      margin-right: 20px

  .header-search-form
    flex: 0 0 696px
    display: inline-flex
    height: 40px
    position: relative
    left: -35px
    box-shadow 0px 2px 3px 0px rgba(0, 0, 0, 0.2)
    border-radius: 5px
    .outcome-name-search-container
    input
      flex: 1 0 auto
    input
      line-height: 18px
      &[name="outcome-name"]
        border-top-right-radius: 0
        border-bottom-right-radius: 0
        border-right: none
      &[name="context"]
        border-radius: 0
        border-right: none
    button
      height: 40px
      flex: 0 0 40px
      font-family: 'Open Sans', sans-serif
      background-color: #ffffff
      border: DEFAULT_BORDER
      border-top-left-radius: 0
      border-bottom-left-radius: 0
      &:before
        content: ' '
        display: inline-block
        width: 20px
        height: 20px
        vertical-align: middle
        background-image: url('../assets/images/search_icon.svg')
