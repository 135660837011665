@import 'globals'

.search-results-container
  margin-bottom: 70px
  .results-header
    padding: 29px 24px 4px 24px
    margin: 0 3px 24px
    border-bottom: 1px solid #b3b3b3
    font-family: 'Open Sans', sans-serif
    font-weight: 300
    color: TEXT_ALT2_COLOR
    .count
      font-weight: bold
  .marker-states-container
    padding-bottom: 5px
    .marker-state-item
      box-sizing: border-box
      height: 180px
      overflow-y: auto
      padding-bottom: 21px
      padding-right: 81px
      .item-line.background
        display: flex
        flex-direction: row
        max-height: 40px
        overflow-y: auto
