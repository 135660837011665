@import 'globals'

.drafts-container
  position: relative
  width: 1106px
  margin-bottom: 70px
  .spinner-container
    text-align: center
  .btn
    height: 30px
    width: 140px
    line-height: 20px
  .drafts-title-block
    height: 143px
    margin-bottom: 15px
    padding-left: 70px
    padding-right: 43px
    box-sizing: border-box
    font-family: 'Rajdhani', sans-serif
    letter-spacing: 0.34px
    color: TEXT_COLOR
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    .text-container
      .help-text
        margin-top: 20px
        font-size: 14px
    .buttons-container
      .btn
        display: block
        position: relative
        width: 193px
      button
        margin-top: 20px
      a, a:hover, a:visited
        text-decoration: none
        line-height: 30px
        text-align: center
  .drafts
    padding: 15px 0 19px 0
    .marker-state-item.draft
      position: relative
      padding: 13px 0 9px 70px
      .outcome-name
        box-sizing: border-box
        height: 36px
        padding: 8px 0
        letter-spacing: 0.43px
        span
          vertical-align: top
        .controls
          margin-left: 20px
          .delete
            margin-left: 20px
      .publish
        position: absolute
        right: 70px
        top: 18px
  .publish-all
    float: right
    margin-top: 20px
    margin-right: 70px
