@import 'globals'
footerHeight = 97px

footer
  position: absolute
  bottom: 0
  width: 100%
  height: footerHeight
  box-sizing: border-box
  bottom: 0
  padding: 0 150px
  vertical-align middle
  background-color: FOOTER_BACKGROUND
  font-family: 'Open Sans', sans-serif
  letter-spacing: 0.36px
  color: #ffffff
  box-shadow 0 -5px 20px -2px #848484
  &:before, &:after
    content: ' '
    display: block
    position: absolute
    height: 100%
    width: 100px
    top: 0
  &:before
    left: 0
    background-image: url('../assets/images/triangles_foot_left.svg')
  &:after
    right: 0
    background-image: url('../assets/images/triangles_foot_right.svg')
  img
    position: absolute
    width: 114px
    height: 123px
    bottom: footerHeight - 7px
    right: 160px
  .footer-container
    text-align: right
    button
      width: 140px
      margin-left: 20px
      background-color: FEEDBACK_BUTTON_BACKGROUND
    .copyright-notice
      margin-top: 14px
      span
        font-weight: 300
  a, a:visited, a:hover, a:active
    font-weight: 600
    color: #ffffff
    text-decoration:none
    span
      font-weight: 300
  .feedback-dialog-container
    position: absolute
    .feedback-dialog
      width: 685px
      height: 310px
      left: calc((100vw - 685px)/2)
      .title
        margin: 46px 0 20px 0
        font-size: 17px
      > div
        width: 494px
        position: relative
      input
      textarea
        padding: 5px
        color: inherit
        font-family: inherit
        font-size: 16px
        background-color: #ffffff
        &:invalid
          border-color: red
      .inputs-container
        display: flex
        justify-content: space-between
        margin-bottom: 15px
        input
          width: calc((100% - 15px)/2)
          height: 35px
          box-sizing border-box
      .text-container__with-submit
        position: relative
        padding-bottom: 30px
        border-radius: 5px
        border: 1px solid BTN_INFO_COLOR
        textarea
          width: 100%
          height: 90px
          box-sizing: border-box
          resize: none
          border: none
          border-radius: 5px
          &:focus
            outline: none
        button
          height: 30px
          width: 100%
          position: absolute
          bottom: 0
          left: 0
          border-radius: 3px
          border-top-left-radius: 0
          border-top-right-radius: 0
          &[disabled]
            opacity: 0.6
