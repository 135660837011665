@import 'globals'

.marker-states-details
  width: 1106px
  margin-bottom: 70px
  .btn-back
    margin-bottom: 10px
  .marker-state-item
    padding-right: 13px
  .icon
    position: absolute
    top: 13px
    right: 13px
  .cancel-save-icons
    .cancel
      top: 50px
  .marker-state-bottom-buttons
    margin-top: 23px
    .btn
      width: 140px
    .cancel-save-buttons
      display: inline-block
      float: right
    .btn-action
      margin-left: 15px

.marker-states-container
  width: 100%
  max-width: 1106px;
  .marker-states-details-header
    padding-top: 23px
    position: relative
    .outcome-name
      font-size: 22px
    .marker-state-item:hover
      background: initial

  .details-tabs
    margin-top: -14px
    .tabspane
      padding-right: 13px
    .marker-details-tab
      padding: 40px 13px 45px 24px
      .marker-rating-desc
        padding-bottom: 44px
        .desc-label
          color: LABEL_PRIMARY_COLOR
          font-size: 18px
          font-family: 'Rajdhani', sans-serif
          font-weight: bold
          letter-spacing: 0.48px
          padding-bottom: 11px
          span
            border-bottom: 2px LABEL_PRIMARY_COLOR solid
            padding-left: 33px
        .desc
          padding-left: 52px
          padding-right: 63px
          font-family: 'Open Sans', sans-serif
          font-size: 14px
          color: TEXT_COLOR
          letter-spacing: 0.34px
          line-height: 21px
          white-space: pre-wrap
          textarea
            font-family: 'Open Sans'
            font-size: 14px
            letter-spacing: 0.34px
            color: TEXT_COLOR
            padding: 5px
            width: 95%
            min-height: 40px
            border-radius: 10px
            resize: vertical

.marker-state-item
  padding-left: 44px
  font-family: 'Rajdhani', sans-serif
  font-size: 14px
  letter-spacing: 0.34px
  color: TEXT_COLOR
  &:hover
    background: MARKER_STATE_BACKGROUND

  .outcome-link
    display: inline-block
    text-decoration: none

  .item-line
    padding-bottom: 8px

  .outcome-name
    padding-top: 8px
    font-size: 18px
    font-weight: bold
    letter-spacing: 0.48px
    color: LABEL_PRIMARY_COLOR

  .label
    margin-right: 5px
    font-weight: 600

  .item-line.background
    display: flex
    flex-direction: row

  .DayPicker-Container
    position: relative
    .DayPicker
      position: absolute
      top: -5px
      right: 40px
      background-color: #eaeaea
      z-index: 99

  .edit-form
    fieldset
      border: none
      > label
      input[type="text"]
        margin-bottom: 8px
      > label
        display: inline-block
        width: 15%
        color: LABEL_PRIMARY_COLOR
        font-family: 'Rajdhani'
        font-size: 15px
        letter-spacing: 0.36px
        font-weight: bold
      .outcome-name-container,
      input[type="text"]
        display: inline-block
        box-sizing: border-box
        width: 82%
        color: TEXT_COLOR
      .outcome-name-container
        position: relative
        > input
          width: 100%
          cursor: pointer
      input#author
        width: 55%
      label[for="dateCreated"]
        width: 5%
        margin-left: 10px
      input#dateCreated
        width: 12%
  .modal-dialog .dialog-content.outcome-choose-dialog
    width: 722px
    min-height: 217px
    padding: 46px 35px 20px
    box-sizing: border-box
    left: calc((100vw - 722px) / 2)
    .title-text
      letter-spacing: 0.48px
    .search-container
      position: relative
      width: 100%
      .outcome-name-search-container
        width: 100%
        margin: 34px 0 29px
        input
          box-sizing: border-box
          &.danger
            border-color: BTN_DANGER_COLOR
      .no-outcome-text
        margin-bottom: 43px
        text-align: left
        font-size: 15px
        letter-spacing: 0.36px
        line-height: 22px
        color: HELP_TEXT_COLOR
    .buttons
      align-self: flex-end
      button
        height: 35px
        width: 140px
        &.create-outcome
          margin-right: 10px
