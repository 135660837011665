@import 'globals'
// FONTS
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Rajdhani');
@import url('https://fonts.googleapis.com/css?family=Lato');
// NODE MODULES
@import '../../node_modules/react-day-picker/lib/style.css';
// VIEWS
@import 'header'
@import 'footer'
@import 'home'
@import 'card_with_button'
@import 'marker_states'
@import 'tabs'
@import 'forms'
@import 'search_results'
@import 'drafts'
@import 'outcomes'

body
  margin: 0

#app
  height: 100vh
  min-width: 1050px

.application-container
  position: relative
  display: flex
  height: 100%
  flex-direction: column
  font-size: 15px

header, footer
  display: flex
  flex: 0 0 auto
  align-items: center
  justify-content: space-between

.spinner-container
  text-align: center

.main-content-with-footer
  position: relative
  display: flex
  flex-direction: column
  flex: 1 0 auto
  box-sizing: border-box
  background: MAIN_CONTAINER_BACKGROUND

.main-content-container
  display: flex
  flex: 1 0 auto
  justify-content: center
  padding-top: 38px
  padding-bottom: 97px

.ms-container
  border-radius: 10px
  box-shadow 5px 5px 15px -2px #848484
  border: DEFAULT_BORDER
  background-color: #ffffff

.btn
  cursor: pointer
  height: 30px
  border-radius: 5px
  border: none
  color: #ffffff
  font-family: 'Lato', sans-serif
  font-size: 16px
  letter-spacing: 0.38px
  cursor: pointer

.btn-primary
  background: BTN_PRIMARY_COLOR

.btn-danger
  background: BTN_DANGER_COLOR

.btn-info
  background: BTN_INFO_COLOR

.btn-back
  background: BTN_BACK_COLOR

.btn-cancel
  background: BTN_CANCEL_COLOR

.btn-action
  background: BTN_ACTION_COLOR

input
  padding: 10px
  color: TEXT_ALT2_COLOR
  background-color: #ffffff
  border-radius: 5px
  border: DEFAULT_BORDER
  font-size: 14px
  font-family: 'Open Sans', sans-serif

.arrow-right:before
  content: ' '
  display: inline-block
  width: 12px
  height: 20px
  vertical-align: middle
  background-image: url('../assets/images/arrow.svg')

.arrow-left:before
  content: ' '
  display: inline-block
  width: 12px
  height: 20px
  vertical-align: middle
  background-image: url('../assets/images/arrow.svg')
  transform: rotate(180deg)

.tab
  font-family: 'Lato', sans-serif
  font-size: 13px
  letter-spacing: 0.38px

.spinner-icon
  display: inline-block
  width: 100px
  height: 100px
  background-image: url('../assets/images/spinner.svg')

button[disabled]
  opacity: 0.5
  cursor: default

button.icon
  background: none
  background-repeat: no-repeat
  border: 0
  cursor: pointer

.icon
  display: inline-block
  &.save
    background-image: url('../assets/images/save_icon.svg')
  &.edit
    background-image: url('../assets/images/edit_icon.svg')
  &.delete
    background-image: url('../assets/images/delete_icon.svg')
  &.flag
    background-image: url('../assets/images/flag_icon.svg')
  // TODO - cancel icon uses delete background as we don't have cancel icon in assets'
  &.cancel
    background-image: url('../assets/images/cross_icon.svg')
  &.expand
  &.collapse
    background-image: url('../assets/images/drop_icon.svg')
    width: 20px
    height: 10px
  &.collapse
    transform: rotate(180deg)

.error-notification-container
  position: fixed
  top: 0
  width: 100%
  text-align: center
  z-index: 4
  .error-notification
    display: inline-block
    padding: 0 20px
    line-height: 25px
    background-color BTN_DANGER_COLOR
    border-radius: 5px
    color: #ffffff
    font-family: 'Open Sans', sans-serif
    font-size: 15px
    button
      border: none
      background: none
      text-decoration: underline
      color: inherit
      font: inherit

.pagination-container
  margin-top: 18px
  margin-bottom: 18px
  text-align: center
  font-family: 'Open Sand', sans-serif
  font-weight: 300
  font-size: 14px
  .active
    font-size: 18px
    font-weight: 600
  a, a:visited, a:active, a:hover
    display: inline-block
    width: 20px
    text-decoration: none
    color: TEXT_ALT_COLOR

.title-text
  font-family: 'Rajdhani', sans-serif
  font-weight: 600
  font-size: 20px
  letter-spacing: 0.34px
  color: TEXT_COLOR

.outcome-question-type-selection
  display: inline-block
  font-size: 15px
  letter-spacing: 0.34px
  .outcome-type
    margin-left: 20px
    input
      margin-right: 10px

.modal-dialog
  .overlay
    display: block
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: #ffffff
    opacity: 0.5
    z-index: 3
  .dialog-content
    width: 550px
    display: flex
    flex-direction: column
    align-items: center
    position: fixed
    top: 20vh
    left: calc((100vw - 550px)/2)
    box-sizing: border-box
    color: TEXT_ALT3_COLOR
    text-align: center
    z-index: 4

.confirmation-dialog
  padding: 20px
  min-height: 140px
  height: auto
  justify-content: space-between
  .title
    font-size: 16px
  .confirmation-buttons
    display: flex
    width: 100%
    margin-top: 20px
    justify-content: space-between
    button
      width: 140px
      height: 35px
.notification-dialog
  white-space: pre-wrap
  .confirmation-buttons
    margin-top: 20px
    button
      width: 140px
      height: 35px

.modal-dialog .dialog-content.new-outcome-dialog
  width: 722px
  height: 220px
  left: calc((100vw - 772px) / 2)
  padding: 43px 35px 20px
  font-family: 'Rajdhani', sans-serif
  .title-text
    letter-spacing: 0.48px
    margin-bottom: 32px
  input[name="outcome-name"]
    width: 100%
    box-sizing border-box
    margin-bottom: 0
    color: TEXT_COLOR
  .question-type
    margin-top: 10px
    align-self: flex-start
    font-size: 15px
    letter-spacing: 0.34px
  button
    width: 140px
    align-self: flex-end

.ellipsized-text
  line-height: 16px

.outcome-name-search-container
  display: inline-block
  position: relative
  input
    width: 100%
    box-sizing: border-box
  .search-results
    position: absolute
    box-sizing: border-box
    width: 100%
    border-radius: 0
    cursor: pointer
    text-align: left
    z-index: 3
    .result
      padding: 5px
      &:hover
      &:focus
        background: MARKER_STATE_BACKGROUND
