@import 'globals'

.home-container
  display: flex
  justify-content: center
  flex-wrap: wrap
  .search-form-container
    margin-right: 55px
    .outcome-name-search-container
      width: 100%
  .gdt-box
    width: 368px
    border-color: GRADEPRO_RED
    h2
      margin: 49px 0 27px 34px
      text-align: left
    div
      padding: 0 34px
      line-height: 24px
      letter-spacing: 0.36px
    .btn-danger
      background: GRADEPRO_RED

  .description-box
    margin: 55px 0px 120px
    padding: 10px 0px
    width: auto
    min-width: 300px
    max-width: 1110px
    border-color: BTN_DANGER_COLOR
    font-family: 'Open Sans', sans-serif
    div
      padding: 0px 34px
      line-height: 24px
      letter-spacing: 0.36px
    &.expanded
      height: auto
    &.collapsed
      height: 120px
      overflow: hidden
    .description-read-more-btn
      float: right
      border: none
      outline: none
      background: none
      cursor: pointer
      color: BTN_PRIMARY_COLOR
.resources
  padding: 0px 34px
  margin-bottom: 20px
  .resources-link--pdf
    display: block
    padding: 0px 0px 10px